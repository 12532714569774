
.Box{
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
}

.Box.a{
  background-color: #FAF8EA;
}

.Box.b{
  background-color: #CBEBEB;
}

.Box.c{
  background-color: #F3F9FC;
}

.Box .title{
  margin: 20px;
  margin-top: 40px;
  font-family: bold;
  text-align: center;
  font-size: 30px;
}

.Box.a .title{
  color: #A09754;
}

.Box.b .title{
  color: #4D9D9D;
}

.Box.c .title{
  color: #238FC4;
}

.Box .text{
  color: #4E4E4E;
  font-size: 20px;
  line-height: 35px;
  margin: 40px;
  margin-bottom: 20px;
}

.Box .image.big_center{
  max-width: 50%;
  margin: 20px;
  margin-bottom: 40px;
}

.Box .image.medium_center{
  max-width: 30%;
  margin: 20px;
  margin-bottom: 40px;
}

.Box .image.full_bottom{
  width: 100%;
  margin-bottom: -4px;
}

.Box .image.medium_out_left{
  max-width: 45%;
  margin-bottom: -10%;
  margin-left: -70%;
}

@media only screen and (max-width: 768px) {

  .Box .text{
    margin: 20px;
    margin-bottom: 20px;
    line-height: 30px;
  }

}



