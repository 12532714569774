
.Column {
  display: flex;
  flex-direction: row;
  margin: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Column .singleColumn{
  display: flex;
  flex-direction: column;
  margin: 20px;
  flex: 1;
}

@media only screen and (max-width: 768px) {

  .Column {
    flex-direction: column;
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .Column .singleColumn{
    margin: 0px;
  }

}