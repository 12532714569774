
.App {
  display: flex;
  flex-direction: column;
}

.footer{
  background-color: #FAF8EA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.footer .measureKit{
  color: #E28638;
  font-size: 40px;
  line-height: 50px;
  font-family: 'bold';
}

.footer .sienergie{
  height: 50px;
}


@media only screen and (max-width: 768px) {

  .footer{
    flex-direction: column;
  }

  .footer .measureKit{
    margin-bottom: 30px;
  }

  .footer .sienergie{
    max-width: 200px;
    height: auto;
  }

}