
@font-face {
  font-family: 'bold';
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf')
}

@font-face {
  font-family: 'light';
  src: url('assets/fonts/Roboto/Roboto-Light.ttf')
}

@font-face {
  font-family: 'regular';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf')
}


body {
  margin: 0;
  padding: 0;
  font-family: 'regular';
  color: #27282C;
}

p{
  margin: 0px;
}

b{
  font-family: 'bold';
}

img{
  user-select: none;
  -webkit-user-drag: none;
}