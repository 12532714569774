
.Row {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: self-start;
  justify-content: center;
}

@media only screen and (max-width: 768px) {

  .Row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}