
.Title{
  margin: 20px;
  color: #E28638;
  border-left: 6px solid #E28638;
  padding: 20px;
  font-family: bold;
}

.Title p{
  font-size: 50px;
  line-height: 60px;
}