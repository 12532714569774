
@font-face {
  font-family: 'bold';
  src: url(/static/media/Roboto-Bold.ee7b96fa.ttf)
}

@font-face {
  font-family: 'light';
  src: url(/static/media/Roboto-Light.fc84e998.ttf)
}

@font-face {
  font-family: 'regular';
  src: url(/static/media/Roboto-Regular.3e1af3ef.ttf)
}


body {
  margin: 0;
  padding: 0;
  font-family: 'regular';
  color: #27282C;
}

p{
  margin: 0px;
}

b{
  font-family: 'bold';
}

img{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer{
  background-color: #FAF8EA;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
}

.footer .measureKit{
  color: #E28638;
  font-size: 40px;
  line-height: 50px;
  font-family: 'bold';
}

.footer .sienergie{
  height: 50px;
}


@media only screen and (max-width: 768px) {

  .footer{
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .footer .measureKit{
    margin-bottom: 30px;
  }

  .footer .sienergie{
    max-width: 200px;
    height: auto;
  }

}

.Column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Column .singleColumn{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

@media only screen and (max-width: 768px) {

  .Column {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .Column .singleColumn{
    margin: 0px;
  }

}

.Title{
  margin: 20px;
  color: #E28638;
  border-left: 6px solid #E28638;
  padding: 20px;
  font-family: bold;
}

.Title p{
  font-size: 50px;
  line-height: 60px;
}

.Box{
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
}

.Box.a{
  background-color: #FAF8EA;
}

.Box.b{
  background-color: #CBEBEB;
}

.Box.c{
  background-color: #F3F9FC;
}

.Box .title{
  margin: 20px;
  margin-top: 40px;
  font-family: bold;
  text-align: center;
  font-size: 30px;
}

.Box.a .title{
  color: #A09754;
}

.Box.b .title{
  color: #4D9D9D;
}

.Box.c .title{
  color: #238FC4;
}

.Box .text{
  color: #4E4E4E;
  font-size: 20px;
  line-height: 35px;
  margin: 40px;
  margin-bottom: 20px;
}

.Box .image.big_center{
  max-width: 50%;
  margin: 20px;
  margin-bottom: 40px;
}

.Box .image.medium_center{
  max-width: 30%;
  margin: 20px;
  margin-bottom: 40px;
}

.Box .image.full_bottom{
  width: 100%;
  margin-bottom: -4px;
}

.Box .image.medium_out_left{
  max-width: 45%;
  margin-bottom: -10%;
  margin-left: -70%;
}

@media only screen and (max-width: 768px) {

  .Box .text{
    margin: 20px;
    margin-bottom: 20px;
    line-height: 30px;
  }

}





.Row {
  background-color: #EEEEEE;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px;
  -webkit-align-items: self-start;
          align-items: self-start;
  -webkit-justify-content: center;
          justify-content: center;
}

@media only screen and (max-width: 768px) {

  .Row {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

}

.RowItem{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.RowItem img{
  max-width: 100px;
}

.RowItem p{
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #4E4E4E;
}

@media only screen and (max-width: 768px) {

  .RowItem{
    margin: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

}
