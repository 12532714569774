
.RowItem{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.RowItem img{
  max-width: 100px;
}

.RowItem p{
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #4E4E4E;
}

@media only screen and (max-width: 768px) {

  .RowItem{
    margin: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

}